<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :columns="columns"
        :scroll="{ x: 0 }"
        :hideRowSelection="true"
        :is-full="true"
        :current-page="pageNumber"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitLinkClick="linkClick"
    >
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="7">
          <a-input
            v-model="searchDto.ProjectName"
            placeholder="选择项目"
            @click="selectProjectModal"
          >
          </a-input>
        </a-col>
        <!-- <a-col :span="4">
          <a-input v-model="searchDto.TaskCode" placeholder="任务编码" />
        </a-col> -->
        <a-col :span="5">
          <a-input
            v-model="searchDto.TaskName"
            placeholder="任务名称/任务编码"
          />
        </a-col>
        <a-col class="gutter-row" :span="3">
          <a-month-picker
            v-model="searchDto.StatisticalMonth"
            placeholder="统计月份"
            valueFormat="YYYY-MM"
            style="width: 100%"
          />
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="searchData"
            v-if="isGranted('Pages.TaskSharePermissions.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" style="text-align: right" :span="5">
          <a-button-group>
            <a-button
              v-if="isGranted('Pages.TaskSharePermissions.Export')"
              @click="exportToExcel()"
            >
              <a-icon type="file-excel" />
              <span>{{ l("Export") }}</span>
            </a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import { fileDownloadService } from "@/shared/utils";
import SelectPageList from "@/components/linggong/select-page-list";
import { CommonServiceProxy } from "@/shared/common-service";
import sharelist from "./sharelist/sharelist";

export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      searchDto: {
        ProjectId: "",
        ProjectName: "",
        TaskCode: "",
        TaskName: "",
        StatisticalMonth: "",
        SkipCount: 0,
        MaxResultCount: 50,
        FilterText: "",
      },
      commonService: null,
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      columns: [],
      PayCompanys: [],
      Customers: [],
      FilterStatus: [
        { label: "全部的项目", value: 0 },
        { label: "有数据项目", value: 1 },
      ],
    };
  },
  methods: {
    initColumns() {
      var vm = this;
      this.columns = [
        {
          title: "姓名",
          dataIndex: "sharePersonnelName",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "sharePersonnelName" },
        },
        {
          title: "手机号",
          dataIndex: "phone",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "phone" },
        },
        {
          title: "身份证",
          dataIndex: "idCard",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "idCard" },
        },
        {
          title: "项目编码",
          dataIndex: "projectCode",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "projectCode" },
        },
        {
          title: "项目名称",
          dataIndex: "projectName",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: "任务编码",
          dataIndex: "taskCode",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "taskCode" },
        },
        {
          title: "任务名称",
          dataIndex: "taskName",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "taskName" },
        },
        {
          title: "次数",
          dataIndex: "shareCount",
          align: "center",
          // width: 50,
          type: "link",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: text,
              },
              on: {
                click: function () {
                  vm.taskShareClick(record);
                },
              },
            });
            var obj = {
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "shareCount" },
        },
      ];
    },
    taskShareClick(item) {
      this.lookTaskShareClick(item);
    },
    lookTaskShareClick(item) {
      console.log(item);
      ModalHelper.create(
        sharelist,
        {
          projectId: item ? item.projectId : "",
          taskId: item ? item.taskId : "",
          sharePersonnelId: item ? item.sharePersonnelId : "",
          statisticalMonth: item ? item.statisticalMonth : "",
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    signingClick(item) {
      this.lookSigningClick(item);
    },
    lookSigningClick(item) {
      ModalHelper.create(
        signinglist,
        {
          projectId: item ? item.projectId : "",
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }

          this.searchDto.ProjectId = projectIds;
          this.searchDto.ProjectName = projectNames;
          this.getData();
        }
      });
    },
    /**
     *
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     *
     * */
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     *
     * */
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    /**
     *
     */
    getData() {
      this.spinning = true;
      let month = this.searchDto.BatchMonth;
      console.info(month);
      let options = {
        url: this.$apiUrl + "/api/services/app/ProjectUser/GetTaskSharePaged",
        method: "GET",
        params: Object.assign(
          {
            projectId: this.searchDto.ProjectId,
            taskCode: this.searchDto.TaskCode,
            taskName: this.searchDto.TaskName,
            statisticalMonth: this.searchDto.StatisticalMonth,
            skipCount: this.searchDto.SkipCount,
            maxResultCount: this.searchDto.MaxResultCount,
          },
          {
            projectId: null,
            projectIds: this.searchDto.ProjectId,
          }
        ),
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.searchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.searchDto.MaxResultCount + 1,
              this.pageNumber * this.searchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    searchData() {
      this.searchDto.SkipCount = 0;
      this.getData();
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectUser/GetTaskShareToExcelFile",
          params: Object.assign(
            {
              projectId: this.searchDto.ProjectId,
              taskCode: this.searchDto.TaskCode,
              taskName: this.searchDto.TaskName,
              statisticalMonth: this.searchDto.StatisticalMonth,
            },
            {
              projectId: null,
              projectIds: this.searchDto.ProjectId,
            }
          ),
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    clearFilterAndRefresh() {
      this.searchDto.SkipCount = 0;
      this.getData();
    },
    // ��ҳ�¼�
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    linkClick(newV) {
      const { item, index } = newV;
      if (index === 7) {
        this.taskShareClick(item);
      }
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.searchDto.SkipCount = 0;
      this.searchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.pageNumber = page;
      this.searchDto.SkipCount = (page - 1) * pageSize;
      this.searchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
      this.searchDto.ProjectId = "";
      this.searchDto.ProjectName = "";
      this.searchDto.TaskCode = "";
      this.searchDto.TaskName = "";
      this.searchDto.StatisticalMonth = "";
      this.searchDto.SkipCount = 0;
      this.pageNumber = 1;
      this.getData();
    },
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    this.getAllPayCompany();
    this.getAllCutomer();
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
